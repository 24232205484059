import * as React from 'react';
import { graphql } from 'gatsby';

import BaseLayout from '../components/BaseLayout';
import Recipe from '../components/Recipe';
import { getThemeDef } from '../utils/theme';
import { IThemeDef } from '../types';

interface IndexPageProps {
  data: {
    oliveFeta1: { childImageSharp: { fluid: object } };
    bananaBread1: { childImageSharp: { fluid: object } };
    something1: { childImageSharp: { fluid: object } };
  };
}

export default class IndexPage extends React.Component<IndexPageProps, object> {
  constructor(props: IndexPageProps) {
    super(props);
  }

  render() {
    const theme: IThemeDef = getThemeDef();
    // console.log('Props:\n' + JSON.stringify(this.props, null, 2));
    // console.log(JSON.stringify(this.props.data, null, 2));
    return (
      <BaseLayout theme={theme}>
        <div className="w-full h-full flex flex-row py-4 md:py-8 justify-center">
          <div className="w-5/6 flex flex-col">
            <div className="text-center text-2xl md:text-4xl lg:text-6xl border-b font-medium pb-2">
              The Baking Blueberry
            </div>
            <Recipe
              name="Olive & Feta Bread"
              img={this.props.data.oliveFeta1.childImageSharp.fluid}
            />
            <Recipe name="Banana Bread" img={this.props.data.bananaBread1.childImageSharp.fluid} />
            <Recipe name="Scones" img={this.props.data.something1.childImageSharp.fluid} />
          </div>
        </div>
      </BaseLayout>
    );
  }
}

export const pageQuery = graphql`
  query {
    oliveFeta1: file(relativePath: { eq: "olive_feta_bread_1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bananaBread1: file(relativePath: { eq: "banana_bread_1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    something1: file(relativePath: { eq: "something_1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
