import * as React from 'react';

import { IThemeDef, SEOProps } from '../types';
import { genTwCss } from '../utils/tailwind';
import SEO from './seo';
import Footer from './Footer';

interface LayoutProps {
  children: React.ReactNode;
  theme?: IThemeDef;
  seoProps?: SEOProps;
}

const BaseLayout: React.FC<LayoutProps> = (props) => {
  return (
    <main className={`w-full h-full flex flex-col ${genTwCss(props.theme?.appBase)}`}>
      <SEO />
      <div className="w-full h-full flex flex-col justify-between">
        {props.children}
        <Footer theme={props.theme} />
      </div>
    </main>
  );
};

export default BaseLayout;
