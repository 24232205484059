import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { SEOProps } from '../types';

// https://www.gatsbyjs.org/docs/add-seo-component/
const SEO: React.FC<SEOProps> = (props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            image
            title
          }
        }
      }
    `
  );
  const metaDescription = props.description || site.siteMetadata.description;
  const language = props.lang || 'en';
  const title = props.title || site.siteMetadata.title;
  const helmetMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        language,
      }}
      title={title}
      titleTemplate={props.titlePrefix ? `${props.titlePrefix} | ${title}` : title}
      meta={props.meta ? helmetMeta.concat(props.meta) : helmetMeta}
      defer={false}
    />
  );
};

export default SEO;
