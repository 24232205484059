import * as React from 'react';
import Img from 'gatsby-image';

interface RecipeProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  img?: any;
}

const Recipe: React.FC<RecipeProps> = (props) => {
  return (
    <div className="w-full h-full flex flex-col py-2 md:py-4">
      <span className="pb-2 md:pb-4 text-xl md:text-2xl lg:text-xl font-medium md:font-semibold">
        {props.name}
      </span>
      {props.img && <Img className="border border-phi-white-haze" fluid={props.img} />}
    </div>
  );
};

export default Recipe;
