import { IThemeDef } from '../types';
import { lightThemeDef } from './lightThemeDef';

export type AvailableThemes = 'light';

type IAllThemesType = {
  [themename in AvailableThemes]: IThemeDef;
};

export const allThemes: IAllThemesType = {
  light: lightThemeDef,
};
