import { IThemeColors, IThemeDef } from '../types';

const textColors: IThemeColors = {
  primary: 'text-phi-white-haze',
};

const bgColors: IThemeColors = {
  primary: 'bg-phi-dark-blue',
};

const borderColors: IThemeColors = {
  primary: 'border-gray-300',
  secondary: 'border-phi-white-haze',
};

export const lightThemeDef: IThemeDef = {
  text: textColors,
  bg: bgColors,
  border: borderColors,
  appBase: {
    bg: bgColors.primary,
    border: borderColors.primary,
    font: 'font-sans',
    text: textColors.primary,
  },
  logo: {
    typography: 'no-underline tracking-wide',
    font: 'font-semibold',
    interactive: 'focus:outline-none',
    sizing: 'w-full',
    text: `text-2xl ${textColors.contrast} text-left`,
  },
};
