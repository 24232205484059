import React from 'react';

import { IThemeDef } from '../types';
import { genTwCss } from '../utils/tailwind';

interface FooterProps {
  theme?: IThemeDef;
}

const Footer: React.FC<FooterProps> = ({ theme }) => (
  <div
    className={`w-full flex flex-row flex-no-wrap py-2 px-8 md:px-12 lg:px-16 font-thin text-sm justify-end border-t ${genTwCss(
      theme?.appBase
    )}`}
  >
    <span>Copyright © {new Date().getFullYear()} Anika Bedi. All Rights Reserved</span>
  </div>
);

export default Footer;
